<template>
  <div class="reg">
    <!-- <img src="@/assets/image/questionnaire/regBackground.png" class="backimg" /> -->
    <p class="title">登录</p>
    <div class="body">
      <van-field
        class="my-field"
        v-model="form.phone"
        label="手机号码"
        required
        placeholder="请输入手机号"
      />
      <van-field
        class="my-field"
        label="验证码"
        required
        placeholder="请输入验证码"
        v-model="form.smscode"
      >
        <template #button>
          <span class="code" @click="sendVerify">
            {{
              countDown.status === 'run'
                ? `${countDown.duration}s后重发`
                : '发送验证码'
            }}</span
          >
          <!-- <input type="text" class="codeinput" v-model="form.smscode" /> -->
        </template>
      </van-field>
      <van-button
        class="button"
        color="linear-gradient(90deg, #FF891C, #FF5508)"
        @click="login"
        >登录</van-button
      >
      <van-button
        class="button reg"
        color="linear-gradient(90deg, #FF891C, #FF5508)"
        @click="reg"
        >注册</van-button
      >
    </div>
  </div>
</template>
<script>
import { sendSMS, loginByPhone } from '@/api/login'
import { userTokenKey } from '@/config/index'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      form: {},
      phoneReg: new RegExp(
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      ),
      countDown: {
        timer: null,
        duration: 60,
        status: 'stop',
        start: () => {
          this.countDown.timer =
            this.countDown.timer ||
            setInterval(() => {
              this.countDown.status = 'run'
              this.countDown.duration -= 1
              if (this.countDown.duration <= 0) {
                this.countDown.stop()
              }
            }, 1000)
        },
        stop: () => {
          clearInterval(this.countDown.timer)
          this.countDown.timer = null
          this.countDown.status = 'stop'
          this.countDown.duration = 60
        },
      },
    }
  },
  created() {
    document.title = '登录页'
  },
  computed: {},
  methods: {
    ...mapActions(['setUser']),
    sendVerify() {
      // 发送验证码
      if (this.countDown.timeout) {
        return
      }
      if (this.countDown.status === 'run') return
      if (!this.form.phone) {
        this.$toast.fail('请输入手机号')
        return
      } else if (!this.phoneReg.test(this.form.phone)) {
        this.$toast.fail('请输入正确的手机号')
        return
      }
      this.countDown.timeout = true

      const params = {
        mobile: this.form.phone,
        smsmode: '0',
      }

      sendSMS(params).then((res) => {
        this.countDown.timeout = false
        if (res && res.success) {
          this.$toast.success('验证码发送成功，请查收')
          this.startCountDown()
        }
      })
    },
    startCountDown() {
      // 开始倒计时
      this.countDown.start()
    },
    login() {
      const params = {
        captcha: this.form.smscode,
        mobile: this.form.phone,
        remember_me: true,
      }
      loginByPhone(params).then((res) => {
        if (res && res.success) {
          const data = res.result
          this.$toast.success('登录成功')
          const { token, userInfo } = data

          this.$cookie.set(userTokenKey, token, { expires: 1 })
          this.setUser(userInfo)
          this.$router.push({
            path: '/questionnaire/realName/start',
            query: this.$route.query,
          })
        }
      })
    },
    reg() {
      this.$router.push({
        path: '/questionnaire/realName/reg',
        query: this.$route.query,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.reg {
  overflow: hidden;
  height: 100vh;
  // background-color: #f5fff9;
  .backimg {
    width: 100%;
    position: absolute;
  }
  .title {
    font-size: 96px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #0e0e0e;
    margin-top: 160px;
    margin-left: 65px;
    position: absolute;
  }
  .body {
    width: 1172px;
    border-radius: 56px;
    background-color: white;
    margin: auto;
    margin-top: 409px;
    margin-left: 51px;
    position: absolute;
    padding: 61px 30px 160px;
    .button {
      width: 90%;
      margin-left: 5%;
      color: black;
      font-size: 56px;
      height: 162px;
      border-radius: 80px;
      background-color: white !important;
      border: rgba(255, 85, 8, 1) 2px solid;
      margin-top: 160px;
    }
    .reg {
      margin-top: 80px;
    }
    .code {
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ea5514;
    }
    .codeinput {
      border: none;
      width: 50%;
      margin-left: 20px;
    }
    &-area {
      width: 100%;
      &-icon {
        float: right;
        line-height: 48px;
      }
    }
  }
}

.my-field {
  flex-direction: column;
}
::v-deep .van-field__label {
  width: auto;
  font-size: 48px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #646566;
}
</style>
